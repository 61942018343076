import { graphql } from 'gatsby';

import Layout from '~/components/Layout';
import Login from '~/components/Account/Login';
import Metadata from '~/components/Metadata';
import { navigate } from 'gatsby-plugin-intl';
import { useEffect } from 'react';
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';

const LoginPage = ({ data }) => {
  const { login } = data;
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) navigate('/account/');
  }, [isLoggedIn]);

  return (
    <Layout transparentNav={login.transparentNav} navColor={login.navColor}>
      <Metadata />
      <Login page={login} />
    </Layout>
  );
};

export default LoginPage;

export const query = graphql`
  query LoginPage($locale: String) {
    login: contentfulPage(slug: { eq: "login" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;
