import { Container } from 'theme-ui';
import SpinnerBar from '~/components/Spinner/loader';
import LoginForm from './LoginForm';
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';

const LoginPage = ({ page }) => {
  const { sections } = page;
  const { isFetching } = useAuth();

  if (isFetching) {
    return (
      <Container
        variant="fullwidth"
        sx={{ display: 'flex', width: '100%', height: '75vh', justifyContent: 'center', alignItems: 'center' }}
      >
        <SpinnerBar />
      </Container>
    );
  }
  return (
    <Container
      variant="fullwidth"
      sx={{
        '--maxWidth': ['100%', null, '1536px'],
        mx: [0, 'auto'],
        backgroundColor: 'white',
        py: ['3rem', '9rem'],
        display: ['flex', 'flex'],
        flexDirection: ['column', 'row'],
        justifyContent: ['center', 'center'],
        alignItems: ['center', null, 'unset'],
        '& > div': {
          maxWidth: ['100%', null, 'calc(var(--maxWidth) / 2)'],
          px: ['3.5vw', '4.5vw'],
          width: ['95%', '75%', '50%'],
          input: {
            border: 'none',
            borderBottom: '1px solid black',
            borderRadius: 0,
            mb: ['3rem'],
            fontFamily: 'Gotham A, Gotham B, system-ui, sans-serif',
          },
          button: { height: ['4.8rem'], width: ['14.5rem'] },
          label: { textTransform: 'uppercase', color: 'olive', fontWeight: 'medium', fontSize: ['1.2rem'] },
          h2: {
            mb: ['2rem'],
            fontSize: ['1.8rem'],
          },
          p: {
            mb: ['7.5rem'],
            fontSize: ['1.3rem'],
            lineHeight: 1.4,
            '.alert': { color: 'errorDark' },
          },
          '& > div:last-of-type': {
            mt: '3rem',
            textDecoration: 'underline',
            variant: 'responsive.mobileTablet',
            color: 'olive',
            cursor: 'pointer',
          },
        },
      }}
    >
      <LoginForm section={sections[0]} />
    </Container>
  );
};

export default LoginPage;
