/* eslint-disable import/no-extraneous-dependencies */

import { Container, Label, Input, Box, Text } from 'theme-ui';
import { useForm } from 'react-hook-form';
import { useState, useRef } from 'react';
import { useAuth } from '@chordcommerce/gatsby-theme-autonomy';

import AnimatedButton from '../Generic/AnimatedButton';

const LoginForm = ({ section, forwardSx }) => {
  const loginRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { login } = useAuth();
  const [loginError, setLoginError] = useState();

  const submitForm = async (formData) => {
    const { email } = formData;

    try {
      await login({
        email,
        showUI: true,
        redirectURI: `${window.location.origin}/finalize-login`,
      });
      const event = new CustomEvent('custom-event', {
        bubbles: true,
        detail: { category: 'ux_click', action: 'login_attempt', label: 'success' },
      });
      loginRef.current.dispatchEvent(event);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Container variant="fullwidth" sx={{ ...forwardSx }}>
      <Text as="h1" variant="h4">
        {section.title}
      </Text>
      <Text as="p">{section.subtitle}</Text>
      <Box as="form" onSubmit={handleSubmit((formData) => submitForm(formData))} sx={{ mb: ['3rem', 0] }}>
        <Label htmlFor="email">Email</Label>
        <Input
          type="email"
          {...register('email', {
            required: true,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: 'invalid email address',
            },
          })}
          onChange={() => setLoginError()}
          aria-invalid={errors?.email ? 'true' : 'false'}
          sx={{
            '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
              WebkitBoxShadow: '0 0 0 30px white inset !important',
            },
            '&:-webkit-autofill': {
              WebkitTextFillColor: 'black !important',
            },
          }}
        />
        {errors?.email && errors?.email.type === 'required' && <span role="alert">{errors?.email.message}</span>}

        <AnimatedButton ref={loginRef} type="submit">
          Login
        </AnimatedButton>
      </Box>
      {loginError && (
        <Text as="p" role="alert" className="alert" sx={{ color: 'red', m: '3rem 0 !important' }}>
          {loginError}
        </Text>
      )}
    </Container>
  );
};

export default LoginForm;
